<template>
  <div>
    <Header />
    <!--Beneficios-->
    <div class="DivFull_LeftCenter DivPortaBtnVoltaBeneficios">
      <div class="DivBtnVoltaBeneficios" id="DivBtnVoltaBeneficios">
        <a
          @click="Show_Info()"
          href="javascript:void(0)"
          class="BtnVoltaBeneficios"
          id="BtnVoltaBeneficios"
        >
          <img src="./svg/ShowBeneficios.svg" class="" alt="CLOSE" />
        </a>
      </div>
    </div>

    <div class="DivFull_LeftCenter Beneficios" id="Beneficios">
      <div class="container-fluid">
        <div class="col-md-6">
          <!--<h2 class="HeaderTitulo1 TituloCor1 BackgroundColor2">VISÃO SIMPLES</h2>-->

          <div class="rowBlock">
            <h1 class="TituloCor1 lime TitleFont">
              Lentes de<br />
              Visão Simples
            </h1>

            <h1
              class="TituloCor1 lime TitleFont Hide"
              id="TituloVisaoSimples_Digital"
            >
              Freeform Digital
            </h1>
            <h1
              class="TituloCor1 lime TitleFont Hide"
              id="TituloVisaoSimples_Convencional"
            >
              Convencionais
            </h1>

            <!--<h2 class="HeaderTitulo1 TituloCor1 BackgroundColor2 Hide" id="TituloVisaoSimples"></h2>-->

            <a
              @click="Hide_Info()"
              href="javascript:void(0)"
              class="BtnFechaBeneficios"
              id="BtnFechaBeneficios"
            >
              <img
                src="./svg/CloseBeneficios.svg"
                class="IconeClose"
                alt="CLOSE"
              />
            </a>
          </div>

          <div class="DivEscolhas">
            <p class="MediumTxt White top20">Selecione uma categoria:</p>

            <div class="row top10 Btns_ShowVisaoSimples_Digital">
              <button
                @click="Digital_Button()"
                class="buttonSC"
                id="Mostra_Digital"
              >
                FREEFORM DIGITAL
              </button>
            </div>

            <div class="row Btns_ShowVisaoSimples_Convenciona">
              <button
                @click="Convencional_Button()"
                class="buttonSC"
                id="Mostra_Convencional"
              >
                CONVENCIONAIS
              </button>
            </div>

            <div class="row">
              <a
                @click="Open_Modal()"
                href="javascript:void(0)"
                class="ShowBeneficios marginBT"
                id="AbreModalBeneficios"
              >
                <img src="./svg/OpenCollapse.svg" class="" alt="Saiba mais" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Beneficios-->

    <!--Modal Beneficios-->
    <div class="ModalBeneficios" id="ModalBeneficios">
      <div class="HeaderModal">
        <h2 class="TituloCor1 lime TitleFont">Lentes de Visão Simples</h2>
      </div>

      <a
        @click="Close_Modal()"
        href="javascript:void(0)"
        class="BtnCloseModal"
        id="FechaModalBeneficios"
      >
        <img src="./svg/CloseModal.svg" alt="Fechar" />
      </a>

      <div class="container ContainerModal">
        <div class="col-12 NoPadding">
          <div class="DivModalBeneficios" id="DivModalBeneficios">
            <!--<div class="owl-carousel owl-theme owl-beneficios">-->
            <carousel
              :autoplay="false"
              :loop="false"
              :nav="true"
              :dots="true"
              :responsive="{
                0: { items: 1, nav: false },
                450: { items: 1, nav: true },
                1000: { items: 1, nav: true },
              }"
            >
              <div class="item_1">
                <div class="row">
                  <img
                    src="./images/VisaoSimples/Banner_VisaoSimples_1.png"
                    class="img-fluid"
                  />
                </div>

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides ModalText">
                    As Lentes de Visão Simples são lentes monofocais, com um
                    único poder de correção em toda a sua superfície, seja para
                    perto ou longe e são indicadas para corrigir dificuldades de
                    visão em pessoas que são diagnosticadas com miopia,
                    hipermetropia e astigmatismo - e que atendem pessoas de
                    qualquer faixa etária.
                  </p>
                </div>
              </div>

              <div class="item_2">
                <img
                  src="./images/VisaoSimples/Banner_VisaoSimples_2.png"
                  class="img-fluid"
                />

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides ModalText">
                    Em lentes convencionais, o campo de visão fica limitado no
                    centro óptico, fazendo com que o usuário tenha que
                    movimentar mais os olhos para focar em objetos em várias
                    distâncias diferentes, este esforço pode causar dores de
                    cabeça, visão embassada e fadiga ocular, comum no final do
                    dia.
                  </p>
                </div>
              </div>

              <div class="item_3">
                <img
                  src="./images/VisaoSimples/Banner_VisaoSimples_3.png"
                  class="img-fluid"
                />

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides ModalText">
                    <strong
                      >Nas Lentes de Visão Simples produzidas pelo moderno
                      processo Digital FreeForm</strong
                    >
                    o poder de correção óptico é confeccionado no lado interno
                    das lentes, aproximando dessa forma a correção aos olhos.<br />
                    São lentes com geometria asférica e atórica, que combinadas
                    com o processo digital de produção resultam em lentes com
                    maior precisão óptica, altíssimo conforto visual e excelente
                    resultado estético, deixando as lentes extremamente finas e
                    com melhor acabamento.
                  </p>
                </div>
              </div>

              <div class="item_4">
                <div class="row">
                  <div class="col-6">
                    <img
                      src="./images/VisaoSimples/VisaoSimples_Freeform.png"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1 lime">
                        TECNOLOGIA DIGITAL FREEFORM
                      </h5>
                      <p class="White Opacidade5 top5 ModalText">
                        São produzidas sob a demanda do usuário, isso é, não são
                        lentes prontas e sim lentes que vão ser confeccionadas
                        exclusivamente para quem as adquiriu.
                      </p>
                    </div>
                  </div>

                  <div class="col-6">
                    <img
                      src="./images/VisaoSimples/VisaoSimples_Olhos.png"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1 lime">MAIOR AMPLITUDE VISUAL</h5>
                      <p class="White Opacidade5 top5 ModalText">
                        Apesar das lentes serem extremamente finas, em média com
                        3mm de espessura, este recurso proporciona um enorme
                        ganho na amplitude dos campos de visão.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="item_5">
                <div class="row">
                  <div class="col-6">
                    <img
                      src="./images/VisaoSimples/VisaoSimples_Perto_1.png"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1 lime">
                        PRODUZIDAS PELOS MÉTODOS CONVENCIONAIS
                      </h5>
                      <p class="White Opacidade5 top5 ModalText">
                        O centro óptico das lentes fica limitado, fazendo com
                        que os olhos se movimentem muito mais para encontrar o
                        ponto mais confortável para leitura.
                      </p>
                    </div>
                  </div>

                  <div class="col-6">
                    <img
                      src="./images/VisaoSimples/VisaoSimples_Perto_2.png"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1 lime">
                        PRODUZIDAS PELO PROCESSO FREEFORM
                      </h5>
                      <p class="White Opacidade5 top5 ModalText">
                        O ponto central das lentes é muito mais amplos, captando
                        muito mais imagens no ponto confortável de visão,
                        diminuindo o movimento ocular.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="item_6">
                <div class="row">
                  <div class="col-6">
                    <img
                      src="./images/VisaoSimples/VisaoSimples_Longe_1.png"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1 lime">
                        PRODUZIDAS PELOS MÉTODOS CONVENCIONAIS
                      </h5>
                      <p class="White Opacidade5 top5 ModalText">
                        Como são lentes com combinações de curvas prontas, o
                        poder de correção fica concentrado numa parte pequena
                        das lentes.
                      </p>
                    </div>
                  </div>

                  <div class="col-6">
                    <img
                      src="./images/VisaoSimples/VisaoSimples_Longe_2.png"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1 lime">
                        PRODUZIDAS PELO PROCESSO FREEFORM
                      </h5>
                      <p class="White Opacidade5 top5 ModalText">
                        A produção FreeForm produz a correção sob medida e na
                        parte interna das lentes, aproximando o poder óptico aos
                        olhos e aumentando a amplitude da visão.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="item_7">
                <div class="row">
                  <div class="col-6">
                    <img
                      src="./images/VisaoSimples/VisaoSimples_Livro_1.png"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1 lime">
                        PRODUZIDAS PELOS MÉTODOS CONVENCIONAIS
                      </h5>
                      <p class="White Opacidade5 top5 ModalText">
                        As bordas das lentes apresentam distorções periféricas
                        que causam aberrações visuais, defeito que incomoda
                        principalmente ao subir escadas.
                      </p>
                    </div>
                  </div>

                  <div class="col-6">
                    <img
                      src="./images/VisaoSimples/VisaoSimples_Livro_2.png"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1 lime">
                        PRODUZIDAS PELO PROCESSO FREEFORM
                      </h5>
                      <p class="White Opacidade5 top5 ModalText">
                        Como a produção das curvas é feita ponto-a-ponto, as
                        distorções são reduzidas ao máximo, proporcionando maior
                        conforto visual.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </carousel>
            <!-- </div>-->
          </div>
        </div>
      </div>
    </div>
    <!--Modal Beneficios-->

    <div
      class="VisaoSimples VisaoSimples_Convencional"
      id="Background_VisaoSimples_Convencional"
    ></div>

    <div
      class="VisaoSimples VisaoSimples_Digital"
      id="Background_VisaoSimples_Digital"
    ></div>

    <div
      class="VisaoSimples VisaoSimples_Hands"
      id="Background_VisaoSimples_Hands"
    ></div>

    <div
      class="VisaoSimples VisaoSimples_Mesa"
      id="Background_VisaoSimples_Mesa"
    ></div>
  </div>
</template>

<script>
import {
  ShowHand,
  Digital_Button,
  Convencional_Button,
} from '@/views/menu/js/new/SimpleLens_Script'
import {
  Hide_Info,
  Show_Info,
  Open_Modal,
  Close_Modal,
} from '@/views/menu/js/new/Scripts'

import Header from '@/views/menu/components/header.vue'
import carousel from 'vue-owl-carousel'

export default {
  components: {
    Header,
    carousel,
  },
  methods: {
    Digital_Button,
    Convencional_Button,
    Hide_Info,
    Show_Info,
    Open_Modal,
    Close_Modal,
  },
  created() {
    ShowHand()
  },
}
</script>

<style scoped>
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css');
@import './css/MyStyle.css';
@import './css/MyLogin.css';
@import './css/MyColors.css';
@import './css/MyMenu.css';
@import './css/MyMenuInterno.css';
@import './css/plugins/animate.css';
@import './css/plugins/hamburger.css';
/* @import "./css/plugins/owl.carousel.min.css"; */
@import './css/plugins/owl.theme.default.min.css';
@import './css/VisaoSimples_Style.css';

.lime {
  color: #abd159;
}
.TitleFont {
  font-family: 'Montserrat', serif;
  padding: 0px 0px;
  margin: 0 0 0 0;
}
.buttonSC {
  color: #ffffff;
  background-color: #0386b2;

  border: none;
  border-radius: 5px;
  display: inline-block;
  width: auto;
  height: auto;

  padding: 8px 12px;
  margin: 0 0 12px 12px;

  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  letter-spacing: 1px;
  text-align: center;
}
.marginBT {
  margin: 0 0 0 12px;
}
.Hide {
  display: none;
}
.DeixaInativo {
  opacity: 0.6;
}
.ModalText {
  margin: 24px 0 0 0;

  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 1.5px;

  color: white;
  opacity: 0.6;
}
</style>
