export function ShowHand() {
  setTimeout(function () {
    document
      .getElementById('Background_VisaoSimples_Hands')
      .classList.add('Show_Hands')
  }, 1800)

  setTimeout(function () {
    document
      .getElementById('Background_VisaoSimples_Digital')
      .classList.add('Show_Digital')
  }, 2500)

  setTimeout(function () {
    document.getElementById('Mostra_Digital').classList.add('DeixaInativo')
    document.getElementById('Mostra_Digital').disabled = true
    document
      .getElementById('TituloVisaoSimples_Digital')
      .classList.remove('Hide')
  }, 2800)
}

export function Convencional_Button() {
  document.getElementById('Mostra_Convencional').classList.add('DeixaInativo')
  document.getElementById('Mostra_Convencional').disabled = true

  document.getElementById('Mostra_Digital').classList.remove('DeixaInativo')
  document.getElementById('Mostra_Digital').disabled = false

  document
    .getElementById('Background_VisaoSimples_Digital')
    .classList.remove('Show_Digital')

  setTimeout(function () {
    document
      .getElementById('Background_VisaoSimples_Hands')
      .classList.add('Baixa_Hands')
  }, 300)

  setTimeout(function () {
    document
      .getElementById('Background_VisaoSimples_Hands')
      .classList.remove('Baixa_Hands')
  }, 800)

  setTimeout(function () {
    document
      .getElementById('Background_VisaoSimples_Convencional')
      .classList.add('Show_Convencional')
  }, 1400)

  document
    .getElementById('TituloVisaoSimples_Convencional')
    .classList.remove('Hide')
}

export function Digital_Button() {
  document
    .getElementById('Mostra_Convencional')
    .classList.remove('DeixaInativo')
  document.getElementById('Mostra_Convencional').disabled = false

  document.getElementById('Mostra_Digital').classList.add('DeixaInativo')
  document.getElementById('Mostra_Digital').disabled = true

  document
    .getElementById('Background_VisaoSimples_Convencional')
    .classList.remove('Show_Convencional')

  setTimeout(function () {
    document
      .getElementById('Background_VisaoSimples_Hands')
      .classList.add('Baixa_Hands')
  }, 300)

  setTimeout(function () {
    document
      .getElementById('Background_VisaoSimples_Hands')
      .classList.remove('Baixa_Hands')
  }, 800)

  setTimeout(function () {
    document
      .getElementById('Background_VisaoSimples_Digital')
      .classList.add('Show_Digital')
  }, 1400)

  document.getElementById('TituloVisaoSimples_Digital').classList.remove('Hide')
}
